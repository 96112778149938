import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const SecondaryBtn = styled(BaseButton)`
	background: transparent;
	border-color: transparent;
  color:#F36224;
  .text{
      text-decoration: underline;
      & + .icon{
        margin-left:0; 
        
      }
    }
	& .icon{
    background: transparent;
    width: 24px;
    
		> svg{
			fill:#F36224;
		}
	}
	&:hover{	
		color:#F36224;
		& .icon{
			background: transparent;
			> svg{
				fill:#F36224;
			}
		}
	}
`

const SecondaryLinkButton = (props) => {
	const { icon, text } = props;
	return(
		<SecondaryBtn className='btn'>
			<span className='text'>{text}</span><span className='icon'>{icon}</span>
		</SecondaryBtn>
	)
}

export default SecondaryLinkButton