import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Section, BreadCrumb, SectionTitle, SectionPageTitle } from "../components/Section";
import { ArrowRight, HomeIcon, ArrowLineIcon } from "../components/Icons";
import SecondaryLinkBtn from "../components/Button/SecondaryLinkButton";
import Img from "gatsby-image";
import BreakpointDown from "../components/Media/BreakpointDown";

const BlogContainer = styled.div`
  max-width: 1150px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`;
const BlogLeft = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
   flex:0 0 100%;
   max-width:100%;
 `}
`;
const BlogRight = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 88px;
  padding-right: 15px;
  ${BreakpointDown.xl`
  padding-left:15px;
 `}
  ${BreakpointDown.lg`
   flex:0 0 100%; 
   max-width:100%;
   margin-top:20px;
 `}
`;

const Card = styled.div`
  display: flex;
  background: transparent;
  flex-direction: column;
  border-bottom: 1px solid #cbd1e2;
  padding-bottom: 25px;
  margin-bottom: 38px;
  ${BreakpointDown.md`
    padding-bottom:20px;
    margin-bottom:20px;
  `}
  &:last-child {
    border-bottom: 0;
    ${BreakpointDown.md`
    padding-bottom:0px;
    margin-bottom:20px;
  `}
  }
`;
const CardImg = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const BlogDate = styled.div`
  background: #223c7e;
  padding: 8px;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 4;
  color: #fff;
  font-size: 18px;
  max-width: 72px;
  line-height: 20px;
  text-align: center;
  padding: 4px;
  font-family: "Poppins", sans-serif;
`;
const CardBody = styled.div`
  margin-top: 30px;
  ${BreakpointDown.md`
  margin-top:15px; 
 `}
 a {
    color: #121316;
    &:hover {
      color: #f36224;
    }
  }
`;
const BlogInfo = styled.div`
  font-size: 16px;
  color: #121316;
  a {
    color: #121316;
    &:hover {
      color: #f36224;
    }
  }
  span {
    &:first-child {
      &:after {
        content: "";
        width: 1px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        margin-right: 3px;
        background: #121316;
      }
    }
  }
`;
const CardDescription = styled.div`
  margin-bottom: 10px;
`;
const CardTitle = styled.h5`
  line-height: 38px;
  margin-bottom: 5px;
  a {
    color: #121316;
    &:hover {
      color: #f36224;
    }
  }
`;
const CardBtn = styled.div`
  span {
    padding-left: 0;
  }
`;

const HeadingBorderBottom = styled.h6`
  border-bottom: 1px solid #cbd1e2;
  padding-bottom: 10px;
  margin-bottom: 0;
`;
const CategoryListItemWrapper = styled.div`
  margin-top: 0px;
`;
const CategoryItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #cbd1e2;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #121316;
  a {
    color: #121316;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    svg {
      fill: #cbd1e2;
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      color: #f36224;
      svg {
        fill: #f36224;
        margin-left: 4px;
      }
    }
  }
  ${BreakpointDown.md`
    padding-top:10px;
  padding-bottom:10px;
  `}
  &:last-child {
    border-bottom: 0;
  }
  svg {
    fill: #cbd1e2;
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    color: #f36224;
    svg {
      fill: #f36224;
      margin-left: 4px;
    }
  }
`;

function BlogCategoryPage({ data, pageContext }) {
  let articlesData = data.allContentfulBlogArticle.nodes;
  let categoriesData = data.allContentfulBlogCategory.nodes;
  const { categoryName } = pageContext;
  const categoryArticles = [];

  articlesData.forEach((article) => {
    article.blogCategories.forEach((category) => {
      if (category.categoryName === categoryName) {
        categoryArticles.push(article);
      }
    });
  });

  return (
    <Layout pageName="blog">
      <SEO title="Blog Category" description="Blog Category" />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        xpt="60px"
        pb="20px"
      >
        <BreadCrumb top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon /> <ArrowRight />
              </Link>
            <Link className="line-after-heading">Knowledge Hub</Link>
            <SectionPageTitle mt="40px" mb="40px">
              Blog Category
            </SectionPageTitle>
          </div>
        </BreadCrumb>
      </Section>
      <div className="gradinat-wrapper">
        <Section
          pt="100px"
          pb="180px"
          xpt="70px"
          xpb="100px"
          mpt="30px"
          bgColor="transparent"
        >
          <BlogContainer>
            <BlogWrapper>
              <BlogLeft>
                {categoryArticles.map((article) => {
                  return (
                    <Card>
                      <CardImg>
                        <BlogDate>{article.postDate}</BlogDate>
                        <Img
                          fluid={article.articleImage.fluid}
                          alt={article.articleImage.title}
                        />
                      </CardImg>
                      <CardBody>
                        <BlogInfo>
                          <span>Author: {article.authorName}</span>{" "}
                          {article.blogCategories.map((category) => {
                            return (
                              <span>
                                <Link to={category.caterogyUrl}>
                                  {category.categoryName}
                                </Link>
                              </span>
                            );
                          })}
                        </BlogInfo>
                        <Link to={article.articleUrl}>
                        <CardTitle>{article.articleHeading}</CardTitle>
                        </Link>
                        <CardDescription
                          dangerouslySetInnerHTML={{
                            __html:
                              article.shortDescription.childMarkdownRemark.html,
                          }}
                        />
                        <CardBtn>
                          <Link to={article.articleUrl}>
                            <SecondaryLinkBtn
                              text="Continue Reading"
                              icon={<ArrowLineIcon />}
                            />
                          </Link>
                        </CardBtn>
                      </CardBody>
                    </Card>
                  );
                })}
              </BlogLeft>
              <BlogRight>
                <CategoryListItemWrapper>
                  <HeadingBorderBottom>Categories</HeadingBorderBottom>
                  {categoriesData.map((category) => {
                    return (
                      <CategoryItem to={category.caterogyUrl}>
                       
                          {category.categoryName}
                        
                        <span>
                          <ArrowLineIcon />
                        </span>
                      </CategoryItem>
                    );
                  })}
                </CategoryListItemWrapper>
              </BlogRight>
            </BlogWrapper>
          </BlogContainer>
        </Section>
      </div>
    </Layout>
  );
}

export default BlogCategoryPage;

export const query = graphql`
  query BlogCategoryPage {
    allContentfulBlogArticle(sort: { order: DESC, fields: postDate }) {
      nodes {
        articleUrl
        articleHeading
        articleImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        postDate(formatString: "DD MMM YYYY")
        authorName
        blogCategories {
          categoryName
          caterogyUrl
        }
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulBlogCategory {
      nodes {
        categoryName
        caterogyUrl
      }
    }
  }
`;
